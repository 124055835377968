export const video = {
  type: "output",
  regex: '<p><img src="(.+(mp4|ogg|webm).*?)"(.+?)\\/>',
  replace: function(match, url, format, other) {
    if (url === "." + format) {
      return match;
    } else {
      return `<video ${other} controls="show" style="max-width: 100%;">
                    <source src="${url}" type="video/${format}">
                        I am sorry, Your browser does not support the <code>video</code> element.</video>`;
    }
  }
};

export const image = {
  type: "output",
  regex: '<img src="(.+(?!(mp4|ogg|webm)).*?)"(.+?)\\/>',
  replace: function(match, url, format, other) {
    if (url === "." + format) {
      return match;
    } else {
      return `<img ${other} src="${url}" style="max-width: 100%;"/>`;
    }
  }
};

export const iframe = {
  type: "output",
  regex: "<iframe ",
  replace: `<iframe style="max-width: 100%" `
};
