import React, { Fragment } from "react";
import { useAuth0 } from "react-auth0-spa";
import { useHistory } from "react-router-dom";
import styles from "./DropdownComponent.module.css";

const Dropdown = () => {
  const { user } = useAuth0();
  const history = useHistory();

  return (
    <Fragment>
      <div className={styles.container}>
        {user.isAdmin && (
          <div
            className={styles.clickable}
            onClick={() => history.push("/dashboard")}
          >
            Admin
          </div>
        )}
        <div
          className={styles.clickable}
          onClick={() => history.push("/logout")}
        >
          Logg av
        </div>
      </div>
    </Fragment>
  );
};

export default Dropdown;
