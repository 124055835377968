const style = getComputedStyle(document.body);
export const colors = {
  Dark_red: style.getPropertyValue("--color-auf-redwine"),
  Dark_blue: style.getPropertyValue("--color-ap-blue"),
  Dark_green: style.getPropertyValue("--color-ap-green-dark"),
  Red: style.getPropertyValue("--color-auf-red"),
  Light_purple: style.getPropertyValue("--color-ap-purple-light"),
  Green: style.getPropertyValue("--color-ap-green"),
  Turquoise: style.getPropertyValue("--color-ap-turquoise"),
  Light_grey: style.getPropertyValue("--color-auf-light-grey"),
  Grey: style.getPropertyValue("--color-auf-grey"),
  Paper_grey: style.getPropertyValue("--color-auf-paper-grey"),
  Paper_white: style.getPropertyValue("--color-auf-paper-white"),
  Yellow: style.getPropertyValue("--color-auf-yellow")
};
