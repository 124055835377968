import { useCms } from "../../../hooks/cms";
import { useApi } from "../../../hooks/api";

import styles from "./QuizView.module.css";
import React, { useState, useEffect } from "react";
import { Quiz } from "../../../utils/types";
import { QuestionComponent } from "./QuestionComponent";

type QuizViewProps = {
  subtopicId: number;
  updateSubtopicMenu: Function;
};

type UserAnswerOnQuestion = {
  questionId: number;
  isCorrect: boolean | null;
};

export const QuizView = (props: QuizViewProps) => {
  const { getQuizPerSubTopicId } = useCms();
  const { postQuizProgress } = useApi();
  const [isLoading, setIsLoading] = useState(true);
  const [quiz, setQuiz] = useState({} as Quiz);
  const [quizAnswerFromUser, setQuizAnswerFromUser] = useState(
    [] as UserAnswerOnQuestion[]
  );
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getQuizPerSubTopicId(props.subtopicId)
      .then(quiz => {
        setQuiz(quiz);
        setIsLoading(false);
        let answers = [] as UserAnswerOnQuestion[];
        quiz.question.forEach(question =>
          answers.push({ questionId: question.id, isCorrect: null })
        );
        setQuizAnswerFromUser(answers);
      })
      .catch(err => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subtopicId]);

  const updateAnswerFromUser = (questionId: number, isCorrect: boolean) => {
    let answerIndex = quizAnswerFromUser.findIndex(
      question => question.questionId === questionId
    );

    let allAnswers = JSON.parse(JSON.stringify(quizAnswerFromUser));

    allAnswers[answerIndex] = {
      ...quizAnswerFromUser[answerIndex],
      isCorrect: isCorrect
    };

    setQuizAnswerFromUser(allAnswers);
  };

  const isQuestionAnsweredCorrect = (questionId: number) => {
    let answer = quizAnswerFromUser.find(
      question => question.questionId === questionId
    );
    return answer?.isCorrect ? true : false;
  };

  const getNumberOfCorrectQuestion = () => {
    let counter: number = 0;
    quiz.question.forEach(question => {
      if (isQuestionAnsweredCorrect(question.id)) {
        counter++;
      }
    });
    return counter;
  };

  const onClickCheckAnswer = () => {
    setShowAnswer(true);
    let numberCorrect = getNumberOfCorrectQuestion();
    let numberQuestions = quiz.question.length;
    let quizComplete = numberCorrect === numberQuestions;
    postQuizProgress(
      props.subtopicId,
      quizComplete,
      numberCorrect,
      numberQuestions
    ).then(_ => props.updateSubtopicMenu(props.subtopicId));
  };

  const showAnswerOfQuestion = (questionId: number) => {
    return showAnswer && isQuestionAnsweredCorrect(questionId);
  };

  return (
    <div>
      <h1 className={styles.quizText}>Quiz</h1>
      {isLoading ? (
        <p>Laster quiz</p>
      ) : (
        <div>
          {quiz.question.map(question => {
            return (
              <div key={question.id}>
                <QuestionComponent
                  id={question.id}
                  Question={question.Question}
                  Answers={question.Answers}
                  updateAnswers={updateAnswerFromUser}
                  showAnswer={showAnswerOfQuestion(question.id)}
                  setShowAnswer={setShowAnswer}
                />
              </div>
            );
          })}{" "}
          <button className={styles.button} onClick={onClickCheckAnswer}>
            Sjekk svar
          </button>
        </div>
      )}
    </div>
  );
};
