import styles from "./TopicHeader.module.css";
import { colors } from "../../../utils/color_config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { MainTopic } from "../../../utils/types";
import history from "../../../utils/history";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

type TopicHeaderProps = {
  mainTopic: MainTopic;
  percentageCompleted: number;
};

const TopicHeader = (props: TopicHeaderProps) => {
  const { mainTopic } = props;

  return (
    <div
      className={styles.header}
      style={{
        backgroundColor: colors[mainTopic.Color]
      }}
    >
      <div className={styles.homeIconWrapper} onClick={() => history.push("/")}>
        <FontAwesomeIcon
          className={`fa-3x ${styles.icon}`}
          icon={faHome}
          size="2x"
        />{" "}
      </div>

      <div className={styles.titleProgressBarWrapper}>
        <div className={styles.title}>{mainTopic.Name}</div>
        <div className={styles.progressBarWrapper}>
          <ProgressBar percentageCompleted={props.percentageCompleted} />
        </div>
      </div>

      <div className={styles.homeIconWrapper} />
    </div>
  );
};

export default TopicHeader;
