import React from "react";

import MainTopicGridComponent from "../../components/MainTopicGrid/MainTopicGridComponent";

import styles from "./MainTopicGridView.module.css";
import { MainTopic } from "../../utils/types";

type MainTopicGridViewProps = {
  mainTopics: Array<MainTopic>;
};

const MainTopicGridView = ({ mainTopics }: MainTopicGridViewProps) => {
  return (
    <div className={styles.gridContainer}>
      {""}
      {mainTopics.map(mainTopic => {
        return (
          <div key={mainTopic.id}>
            <MainTopicGridComponent
              topicId={mainTopic.id}
              topicName={mainTopic.Name}
              color={mainTopic.Color}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MainTopicGridView;
