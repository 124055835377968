import React, { useEffect } from "react";
import { useAuth0 } from "react-auth0-spa";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";

const Logout = () => {
  const { logout, loading } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    if (!loading)
      logout({
        returnTo: `${window.location.origin}`
      });
    else history.push("/");
  });

  return <Loading />;
};

export default Logout;
