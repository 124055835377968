import React from "react";

import styles from "./ProgressBar.module.css";

type ProgressBarProps = {
  percentageCompleted: number;
};

const ProgressBar = ({ percentageCompleted }: ProgressBarProps) => {
  const percentage =
    percentageCompleted > 100 ? 100 : Math.round(percentageCompleted);

  var percentageStyling = {
    width: percentage + "%"
  };

  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar} style={percentageStyling} />
      <p className={styles.text}>{percentage + " %"}</p>
    </div>
  );
};

export default ProgressBar;
