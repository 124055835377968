import { useAuth0 } from "../react-auth0-spa";
import apiConfig from "../config/api_config.json";

export interface IContentProgress {
  contentId: number;
  isComplete: boolean;
}

export interface IQuizProgress {
  isComplete: boolean;
  numCorrectQuestions: number;
  numTotalQuestions: number;
}

export interface ITopicProgress {
  totalComplete: number;
  contentProgress: IContentProgress[];
  quizProgress: IQuizProgress;
}

export const useApi = () => {
  const { apiToken } = useAuth0();
  const basePath = apiConfig.apiUri;

  const getHeaders = () => {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + apiToken
    };
  };

  const getApiData = async path => {
    return fetch(path, {
      method: "GET",
      headers: getHeaders()
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${path}.`);
        }
        return response.json();
      })
      .catch(e => e);
  };

  const postApi = async path => {
    return fetch(path, {
      method: "POST",
      headers: getHeaders()
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${path}.`);
        }
        return response.json();
      })
      .catch(e => e);
  };

  const postApiWithData = async (path, data) => {
    return fetch(path, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(data)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${path}.`);
        }
        return response.json();
      })
      .catch(e => e);
  };

  const getSubTopicProgress = async (
    subtopicId: number
  ): Promise<ITopicProgress> => {
    const path: String = basePath + "/progress/subtopic/" + subtopicId;
    return getApiData(path);
  };

  const getQuizProgress = async (
    subtopicId: number
  ): Promise<IQuizProgress> => {
    const path: String =
      basePath + "/progress/subtopic/" + subtopicId + "/question";
    return getApiData(path);
  };

  const postContentProgess = async (
    subtopicId: number,
    contentId: number,
    isComplete: boolean
  ): Promise<IContentProgress> => {
    const path =
      basePath +
      "/progress/subtopic/" +
      subtopicId +
      "/content/" +
      contentId +
      "?isComplete=" +
      isComplete;

    return postApi(path);
  };

  const postQuizProgress = async (
    subtopicId: number,
    isComplete: boolean,
    numCorrectQuestions: number,
    numTotalQuestions: number
  ): Promise<IQuizProgress> => {
    const path = basePath + "/progress/subtopic/" + subtopicId + "/question";
    const body = {
      isComplete: isComplete,
      numCorrectQuestions: numCorrectQuestions,
      numTotalQuestions: numTotalQuestions
    };

    return postApiWithData(path, body);
  };

  const getProgressByContentId = async (
    subtopicId: number,
    contentId: number
  ): Promise<IContentProgress> => {
    const path: String =
      basePath + "/progress/subtopic/" + subtopicId + "/content/" + contentId;
    return getApiData(path);
  };

  return {
    getSubTopicProgress,
    getQuizProgress,
    postQuizProgress,
    postContentProgess,
    getProgressByContentId
  };
};
