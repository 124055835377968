import React from "react";
import styles from "./TopicContent.module.css";
import { listIndexOf } from "../TopicUtil";

type TopicContentProps = {
  activeSubtopicContents: Array<[number, number | "quiz"]>;
  activeSubtopic: number;
  activeSubtopicContent: number | string;
  setActiveSubtopic: Function;
  setActiveSubtopicContent: Function;
};

const TopicIterator = ({
  activeSubtopicContents,
  activeSubtopic,
  activeSubtopicContent,
  setActiveSubtopic,
  setActiveSubtopicContent
}: TopicContentProps) => {
  const setNextSubtopicContent = () => {
    const currentContent = listIndexOf(activeSubtopicContents, [
      activeSubtopic,
      activeSubtopicContent
    ]);
    const [newSubtopic, newContent] = activeSubtopicContents[
      (currentContent + 1) % activeSubtopicContents.length
    ];
    setActiveSubtopic(newSubtopic);
    setActiveSubtopicContent(newContent);
  };

  const setPreviousSubtopicContent = () => {
    const mod = (n, m) => {
      return ((n % m) + m) % m;
    };

    const currentContent = listIndexOf(activeSubtopicContents, [
      activeSubtopic,
      activeSubtopicContent
    ]);
    const [newSubtopic, newContent] = activeSubtopicContents[
      mod(currentContent - 1, activeSubtopicContents.length)
    ];
    setActiveSubtopic(newSubtopic);
    setActiveSubtopicContent(newContent);
  };

  return (
    <>
      <div className={styles.contentButtonWrapper}>
        <div
          className={styles.contentButton}
          onClick={() => setPreviousSubtopicContent()}
        >
          Tilbake
        </div>
        <div
          className={styles.contentButton}
          onClick={() => {
            setNextSubtopicContent();
          }}
        >
          Neste
        </div>
      </div>
    </>
  );
};

export default TopicIterator;
