import { SubTopic } from "../../utils/types";

export const getActiveSubtopicContents = (subTopics: SubTopic[]) => {
  let topics: Array<[number, number | "quiz"]> = [];

  for (
    let subTopicIndex = 0;
    subTopicIndex < subTopics.length;
    subTopicIndex++
  ) {
    subTopics[subTopicIndex].Content.map((content, contentIndex) =>
      topics.push([subTopicIndex, contentIndex])
    );
    if (subTopics[subTopicIndex].Quiz.length > 0) {
      topics.push([subTopicIndex, "quiz"]);
    }
  }
  return topics;
};

export const listIndexOf = (list, item) => {
  for (let i = 0; i < list.length; i++) {
    if (JSON.stringify(list[i]) === JSON.stringify(item)) {
      return i;
    }
  }
  return -1;
};