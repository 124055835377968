import styles from "./SubTopicMenu.module.css";
import React, { useState } from "react";

import { SubTopic } from "../../../utils/types";
import { SubTopicDropdown } from "./SubTopicDropdown";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type SubTopicMenuProps = {
  subTopics: Array<SubTopic>;
  subTopicsMenuUpdater: number[];
  setActiveSubtopic: Function;
  setActiveSubtopicContent: Function;
  activeContent: [number, number | "quiz"];
};

export const SubTopicMenu = ({
  subTopics,
  setActiveSubtopic,
  setActiveSubtopicContent,
  activeContent,
  subTopicsMenuUpdater
}: SubTopicMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);

  const openMenu = {
    width: "300px",
    padding: "15px"
  };

  const subTopicsDropdowns = subTopics.map((subTopic, index) => (
    <SubTopicDropdown
      key={subTopic.id}
      subTopic={subTopic}
      updater={subTopicsMenuUpdater[index]}
      subTopicIndex={index}
      setActiveSubtopic={setActiveSubtopic}
      setActiveSubtopicContent={setActiveSubtopicContent}
      closeMenu={() => setShowMenu(false)}
      activeContent={activeContent}
    />
  ));

  return (
    <>
      <div
        className={`${styles.SubTopicDropdownContainer} ${styles.SubTopicDropdownContainerDesktop}`}
      >
        {subTopicsDropdowns}
      </div>

      <div
        className={`${styles.SubTopicDropdownContainerMobile}`}
        style={showMenu ? openMenu : {}}
      >
        <div className={`${styles.SubTopicDropdownContainerMobileClosed}`}>
          <FontAwesomeIcon
            className={styles.menu}
            icon={faBars}
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>

        <div
          className={`${styles.SubTopicDropdownContainerMobileOpen}`}
          style={showMenu ? openMenu : {}}
        >
          <div className={styles.closeWrapper}>
            <FontAwesomeIcon
              className={styles.menu}
              icon={faTimes}
              onClick={() => setShowMenu(!showMenu)}
            />
          </div>
          <div
            className={styles.SubTopicDropdownContainer}
            style={{ visibility: showMenu ? "visible" : "hidden" }}
          >
            {subTopicsDropdowns}
          </div>
        </div>
        <div
          className={styles.overlay}
          style={{ display: showMenu ? "block" : "none" }}
          onClick={() => setShowMenu(false)}
        />
      </div>
    </>
  );
};
