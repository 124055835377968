import { useCms } from "../hooks/cms";
import { useApi, IQuizProgress } from "../hooks/api";
import { SubTopic } from "./types";

export const useProgress = () => {
  const { getSubTopicProgress, getQuizProgress } = useApi();
  const {
    getNumberOfContentPerSubTopicId,
    getSubTopicPerSubTopicId
  } = useCms();

  const getNumberCompletePerSubtopicId = async (subtopicId: number) => {
    let completed: number = 0;
    await getSubTopicProgress(subtopicId).then(subtopicProgress => {
      completed += subtopicProgress.totalComplete;
    });

    let subTopic: SubTopic = await getSubTopicPerSubTopicId(subtopicId);

    if (subTopic.Quiz.length > 0) {
      let subTopicProgress: IQuizProgress = await getQuizProgress(subtopicId);
      if (subTopicProgress.isComplete) {
        completed++;
      }
    }
    return completed;
  };

  const getNumberTotalPerSubtopicId = async (subtopicId: number) => {
    let total: number = 0;
    let subTopicContentCount = await getNumberOfContentPerSubTopicId(
      subtopicId
    );

    total = subTopicContentCount.numberOfContent;

    let subTopic: SubTopic = await getSubTopicPerSubTopicId(subtopicId);

    if (subTopic.Quiz.length > 0) {
      total++;
    }
    return total;
  };

  return { getNumberCompletePerSubtopicId, getNumberTotalPerSubtopicId };
};
