import React, { useState } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

import styles from "./UserComponent.module.css";
import Dropdown from "components/Dropdown/DropdownComponent";

const UserComponent = () => {
  const { user } = useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory();

  const onProfileClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={styles.container}>
      <div className={styles.logout} onClick={() => history.push("/logout")}>
        Logg av
      </div>

      <div className={styles.dropdownButton} onClick={onProfileClick}>
        <div className={styles.userIconWrapper}>
          <FontAwesomeIcon className={styles.icon} icon={faUser} size="2x" />
        </div>
        <p className={styles.userName}>{user.nickname}</p>
      </div>
      {showDropdown && <Dropdown />}
    </div>
  );
};

export default UserComponent;
