import React, { useEffect, useState } from "react";
import styles from "./Topic.module.css";

import { useParams } from "react-router";
import { useCms } from "../../hooks/cms";
import { useProgress } from "../../utils/progress";

import Loading from "../../components/Loading/Loading";
import { MainTopic, SubTopic } from "../../utils/types";
import TopicHeader from "./TopicHeader/TopicHeader";
import TopicContent from "./TopicContent/TopicContent";
import { SubTopicMenu } from "./SubTopicMenu/SubTopicMenu";

import { QuizView } from "./Quiz/QuizView";
import TopicIterator from "./TopicContent/TopicIterator";
import { getActiveSubtopicContents } from "./TopicUtil";
import history from "./../../utils/history";

type contentType = number | "quiz";

const Topic = () => {
  const { getMainTopic, getSubTopicsPerMainTopicId } = useCms();
  const {
    getNumberCompletePerSubtopicId,
    getNumberTotalPerSubtopicId
  } = useProgress();
  let { id } = useParams();
  const [mainTopic, setMainTopic] = useState({} as MainTopic);
  const [subTopics, setSubTopics] = useState([] as SubTopic[]);
  const [subTopicsMenuUpdater, setSubTopicsMenuUpdater] = useState(
    [] as number[]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [possibleSubtopicContents, setPossibleSubtopicContents] = useState(
    [] as Array<[number, number | "quiz"]>
  );
  const initialSubtopic =
    history.location.state && history.location.state.subTopicIndex;
  const [activeSubtopic, setActiveSubtopic] = useState(
    initialSubtopic === undefined ? 0 : initialSubtopic
  );
  const [activeSubtopicContent, setActiveSubtopicContent] = useState(
    0 as contentType
  );
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const updateSubtopicMenu = (id: number) => {
    let _subTopicsMenuUpdater = subTopicsMenuUpdater;
    const index = subTopics.findIndex(obj => obj.id === id);
    _subTopicsMenuUpdater[index]++;
    setSubTopicsMenuUpdater(_subTopicsMenuUpdater);
    computeAndSetPercentageCompleted(subTopics);
  };

  const computeAndSetPercentageCompleted = async (subTopics: SubTopic[]) => {
    let sumCompleted = 0;
    let sumTotal = 0;

    for (let index = 0; index < subTopics.length; index++) {
      let completed: number = await getNumberCompletePerSubtopicId(
        subTopics[index].id
      );
      let total: number = await getNumberTotalPerSubtopicId(
        subTopics[index].id
      );
      sumCompleted += completed;
      sumTotal += total;
    }

    let percentage =
      sumCompleted / sumTotal ? Math.floor((sumCompleted / sumTotal) * 100) : 0;

    setPercentageCompleted(percentage);
  };

  useEffect(() => {
    setIsLoading(true);

    let mainTopicPromise = getMainTopic(id).then(mainTopic => {
      setMainTopic(mainTopic);
      document.title = mainTopic.Name;
    });

    let subTopicsPromise = getSubTopicsPerMainTopicId(id).then(subTopics => {
      const activeSubtopicContents = getActiveSubtopicContents(subTopics);
      setPossibleSubtopicContents(activeSubtopicContents);
      if (activeSubtopicContents.length !== 0) {
        if (initialSubtopic === undefined) {
          const [newSubtopic, newContent] = activeSubtopicContents[0];
          setActiveSubtopic(newSubtopic);
          setActiveSubtopicContent(newContent);
        }
        computeAndSetPercentageCompleted(subTopics);
        setSubTopics(subTopics);
        setSubTopicsMenuUpdater(new Array(subTopics.length).fill(0));
      }
    });

    Promise.all([mainTopicPromise, subTopicsPromise]).then(_ => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (isLoading) {
    return <Loading />;
  }

  if (possibleSubtopicContents.length === 0) {
    return (
      <>
        <TopicHeader
          mainTopic={mainTopic}
          percentageCompleted={percentageCompleted}
        />
      </>
    );
  }

  return (
    <div className={styles.topicBody}>
      <TopicHeader
        mainTopic={mainTopic}
        percentageCompleted={percentageCompleted}
      />
      <div className={styles.subTopics}>
        <SubTopicMenu
          subTopics={subTopics}
          subTopicsMenuUpdater={subTopicsMenuUpdater}
          setActiveSubtopic={setActiveSubtopic}
          setActiveSubtopicContent={setActiveSubtopicContent}
          activeContent={[activeSubtopic, activeSubtopicContent]}
        />
        {activeSubtopicContent === "quiz" ? (
          <div className={styles.content}>
            <QuizView
              subtopicId={subTopics[activeSubtopic].id}
              updateSubtopicMenu={updateSubtopicMenu}
            />
          </div>
        ) : (
          <div className={styles.content}>
            <TopicContent
              content={subTopics[activeSubtopic].Content[activeSubtopicContent]}
              activeSubtopic={subTopics[activeSubtopic]}
              activeContent={
                subTopics[activeSubtopic].Content[activeSubtopicContent]
              }
              updateSubtopicMenu={updateSubtopicMenu}
            />
            <TopicIterator
              activeSubtopic={activeSubtopic}
              activeSubtopicContent={activeSubtopicContent}
              activeSubtopicContents={possibleSubtopicContents}
              setActiveSubtopic={setActiveSubtopic}
              setActiveSubtopicContent={setActiveSubtopicContent}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Topic;
