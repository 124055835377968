import styles from "./QuestionComponent.module.css";
import React, { useState, useEffect } from "react";
import { Question, Answer } from "../../../utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type QuestionComponentProps = {
  id: number;
  Question: string;
  Answers: Answer[];
  updateAnswers: any;
  showAnswer: boolean;
  setShowAnswer: any;
};

export const QuestionComponent = (props: QuestionComponentProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [question, setQuestion] = useState({} as Question);

  useEffect(() => {
    setIsLoading(true);
    let questionWithAnswer = {
      id: props.id,
      Question: props.Question,
      Answers: props.Answers
    };
    setQuestion(questionWithAnswer);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isCorrectAnswer = (id: number) => {
    let correctIds = getIdsOfCorrectAnswer();
    return correctIds.indexOf(id) !== -1;
  };

  const getIdsOfCorrectAnswer = () => {
    let ids: number[] = [];
    question.Answers.forEach(answer => {
      if (answer.isCorrect) {
        ids.push(answer.id);
      }
    });
    return ids;
  };

  const onRadioButtonChange = (id: number) => {
    props.updateAnswers(question.id, isCorrectAnswer(id));
    props.setShowAnswer(false);
  };

  return (
    <div className={styles.questionContainer}>
      {isLoading ? (
        <p>Laster spørsmål</p>
      ) : (
        <div key={question.id}>
          <div className={styles.textContainer}>
            <h2 className={styles.question}>{question.Question}</h2>
            <p></p>
          </div>
          <div className={styles.answersContainer}>
            {question.Answers.map(answer => {
              return (
                <div className={styles.answer} key={answer.id}>
                  <label>
                    <input
                      type="radio"
                      name={"" + question.id}
                      onChange={() => {
                        onRadioButtonChange(answer.id);
                      }}
                      className={styles.radioButton}
                    />
                    {answer.Answer + " "}
                  </label>
                  {props.showAnswer && isCorrectAnswer(answer.id) && (
                    <FontAwesomeIcon className={styles.icon} icon={faCheck} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
