import React, { Fragment, useEffect, useState } from "react";
import logo from "../assets/auf_logo.svg";
import styles from "./Home.module.css";

import { useAuth0 } from "../react-auth0-spa";
import { useCms } from "../hooks/cms";

import LogInComponent from "../components/LogIn/LogInComponent";
import MainTopicGridView from "../views/MainTopicGridView/MainTopicGridView";
import UserComponent from "../components/User/UserComponent";
import Loading from "../components/Loading/Loading";

const Home = () => {
  const { isAuthenticated } = useAuth0();
  const { getMainTopics } = useCms();
  const [mainTopics, setMainTopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      document.title = "Opplæringsplatform";
      setIsLoading(true);

      getMainTopics()
        .then(mainTopics => {
          setMainTopics(mainTopics);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img className={styles.logo} src={logo} alt="Logo" />
          <h1 className={styles.title}>Opplæringsplattform</h1>
        </div>

        {isAuthenticated ? (
          <div className={styles.topicContainer}>
            <UserComponent />
            <MainTopicGridView mainTopics={mainTopics} />
          </div>
        ) : (
          <LogInComponent />
        )}
      </div>
    </Fragment>
  );
};

export default Home;
