import styles from "./SubTopicMenu.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { SubTopic } from "../../../utils/types";
import { SubTopicContent } from "./SubTopicContent";
import { IContentProgress, IQuizProgress, useApi } from "../../../hooks/api";

type SubTopicDropdownProps = {
  subTopic: SubTopic;
  setActiveSubtopic: Function;
  setActiveSubtopicContent: Function;
  subTopicIndex: number;
  closeMenu: Function;
  activeContent: [number, number | "quiz"];
  updater: number;
};

export const SubTopicDropdown = ({
  subTopic,
  setActiveSubtopic,
  setActiveSubtopicContent,
  subTopicIndex,
  closeMenu,
  activeContent,
  updater
}: SubTopicDropdownProps) => {
  const { getSubTopicProgress } = useApi();
  const [open, setOpen] = useState(subTopicIndex === activeContent[0]);
  const [contentProgess, setContentProgess] = useState(
    [] as IContentProgress[]
  );
  const [quizProgess, setQuizProgess] = useState({} as IQuizProgress);

  useEffect(() => {
    getSubTopicProgress(subTopic.id).then(subTopicProgress => {
      setContentProgess(subTopicProgress.contentProgress);
      setQuizProgess(subTopicProgress.quizProgress);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTopic, updater]);

  useEffect(() => {
    setOpen(subTopicIndex === activeContent[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContent, subTopicIndex]);

  return (
    <>
      <div className={styles.SubTopicDropdown} onClick={() => setOpen(!open)}>
        <h3 className={styles.subTopicText}>{subTopic.Title}</h3>
        {open ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>
      <div className={styles.SubTopicContentWrapper}>
        {open ? (
          <>
            {subTopic.Content.map((content, contentIndex) => {
              const progress = contentProgess.find(
                item => item.contentId === content.id
              );
              return (
                <SubTopicContent
                  key={content.id}
                  title={content.Title}
                  icon={content.Icon}
                  setContent={() => {
                    setActiveSubtopic(subTopicIndex);
                    setActiveSubtopicContent(contentIndex);
                    closeMenu();
                  }}
                  activeContent={activeContent}
                  subTopicIndex={subTopicIndex}
                  contentIndex={contentIndex}
                  isComplete={
                    progress === undefined ? false : progress.isComplete
                  }
                />
              );
            })}
            {subTopic.Quiz.length === 0 ? null : (
              <SubTopicContent
                title={"Quiz"}
                icon={"quiz"}
                setContent={() => {
                  setActiveSubtopic(subTopicIndex);
                  setActiveSubtopicContent("quiz");
                  closeMenu();
                }}
                activeContent={activeContent}
                subTopicIndex={subTopicIndex}
                contentIndex={"quiz"}
                isComplete={quizProgess.isComplete}
              />
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
