import React from "react";

import styles from "./SubTopicGridComponent.module.css";
import history from "../../utils/history";
import { SubTopic } from "../../utils/types";

type SubTopicGridComponentProps = {
  subTopics: Array<SubTopic>;
  topicId: number;
};

const SubTopicGridComponent = ({
  subTopics,
  topicId
}: SubTopicGridComponentProps) => {
  return (
    <div>
      {" "}
      {subTopics.map((subTopic, subTopicIndex) =>
        subTopic.Content.length > 0 ? (
          <div key={subTopic.id}>
            <div
              className={styles.subTopic}
              onClick={() =>
                history.push(`/topic/${topicId}`, {
                  subTopicIndex: subTopicIndex
                })
              }
            >
              <p className={styles.subTopicName}>{subTopic.Title}</p>
            </div>
          </div>
        ) : (
          <div key={subTopic.id} />
        )
      )}
    </div>
  );
};

export default SubTopicGridComponent;
