import React from "react";
import styles from "./SubTopicMenu.module.css";
import {
  faBook,
  faLightbulb,
  faPlayCircle,
  faUserGraduate
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../utils/color_config";

type SubTopicContentProps = {
  title: string;
  icon: string;
  setContent: Function;
  activeContent: [number, number | string];
  subTopicIndex: number;
  contentIndex: number | "quiz";
  isComplete: boolean;
};

const icons = {
  null: faBook,
  ReadingTask: faBook,
  PracticalTask: faLightbulb,
  Video: faPlayCircle,
  quiz: faUserGraduate
};

export const SubTopicContent = ({
  title,
  icon,
  setContent,
  activeContent,
  subTopicIndex,
  contentIndex,
  isComplete
}: SubTopicContentProps) => {
  const isInActiveSubtopic = activeContent[0] === subTopicIndex;
  const isActive = isInActiveSubtopic && activeContent[1] === contentIndex;

  const isBelowActive =
    isInActiveSubtopic &&
    !isActive &&
    (contentIndex === "quiz" || contentIndex > activeContent[1]);
  const isAboveActive = isInActiveSubtopic && !isActive && !isBelowActive;

  const iconColor = () => {
    const activeStyle = { backgroundColor: colors.Yellow };
    const completeStyle = { backgroundColor: "#62B654" };
    const notCompleteStyle = { backgroundColor: "#afa7a7" };
    if (isComplete && contentIndex === "quiz") {
      return completeStyle;
    } else if (isActive) {
      return activeStyle;
    } else if (isComplete) {
      return completeStyle;
    }
    return notCompleteStyle;
  };

  const lineDirection = () => {
    const downwards = { ...iconColor(), top: "30px" };
    const upwards = { ...iconColor(), bottom: "30px" };
    if (isAboveActive) {
      return downwards;
    }
    if (isBelowActive) {
      return upwards;
    }
    return {};
  };

  return (
    <div className={styles.contentWrapper} onClick={() => setContent()}>
      <div className={styles.contentIconWrapper}>
        <div className={styles.icon} style={iconColor()}>
          <FontAwesomeIcon icon={icons[icon]} />
        </div>
        <div className={styles.colorLine} style={lineDirection()} />
      </div>
      <div
        className={styles.contentName}
        style={isActive ? { fontWeight: "bold" as "bold" } : {}}
      >
        {title}
      </div>
    </div>
  );
};
