import React, { useState, useEffect } from "react";

import SubTopicGridComponent from "../SubTopicGrid/SubTopicGridComponent";
import ProgressBar from "components/ProgressBar/ProgressBar";

import styles from "./MainTopicGridComponent.module.css";
import { colors } from "../../utils/color_config";

import { useCms } from "../../hooks/cms";

import { useProgress } from "../../utils/progress";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubTopic } from "utils/types";

type MainTopicGridComponentProps = {
  topicId: number;
  topicName: string;
  color: string;
};

const MainTopicGridComponent = ({
  topicId,
  topicName,
  color
}: MainTopicGridComponentProps) => {
  const { getSubTopicsPerMainTopicId } = useCms();
  const {
    getNumberCompletePerSubtopicId,
    getNumberTotalPerSubtopicId
  } = useProgress();
  const [open, setOpen] = useState(false);
  const [subTopics, setSubtopics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [percentageCompleted, setPercentageCompleted] = useState(0);

  const colorStyle = (color: string) => ({
    backgroundColor: colors[color]
  });

  const computeAndSetPercentageCompleted = async (subTopics: SubTopic[]) => {
    let sumCompleted = 0;
    let sumTotal = 0;

    for (let index = 0; index < subTopics.length; index++) {
      let completed: number = await getNumberCompletePerSubtopicId(
        subTopics[index].id
      );
      let total: number = await getNumberTotalPerSubtopicId(
        subTopics[index].id
      );
      sumCompleted += completed;
      sumTotal += total;
    }

    let percentage =
      sumCompleted / sumTotal ? Math.floor((sumCompleted / sumTotal) * 100) : 0;

    setPercentageCompleted(percentage);
  };

  useEffect(() => {
    setIsLoading(true);
    getSubTopicsPerMainTopicId(topicId)
      .then(subTopics => {
        setSubtopics(subTopics);
        computeAndSetPercentageCompleted(subTopics);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expandComponent = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      <div
        className={styles.container}
        style={colorStyle(color)}
        onClick={expandComponent}
      >
        <p className={styles.text}>{isLoading ? "Laster..." : topicName}</p>
        <div className={styles.progressBarWrapper}>
          <ProgressBar percentageCompleted={percentageCompleted} />
        </div>
        {open ? (
          <FontAwesomeIcon className={styles.chevron} icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
        )}
      </div>
      {open ? (
        <SubTopicGridComponent subTopics={subTopics} topicId={topicId} />
      ) : null}
    </div>
  );
};

export default MainTopicGridComponent;
