import { Quiz } from "./../utils/types";
import { useAuth0 } from "../react-auth0-spa";
import apiConfig from "../config/api_config.json";

interface ISubTopicContentCount {
  subtopicId: number;
  numberOfContent: number;
}

const useAuthHeaders = () => {
  const { apiToken } = useAuth0();

  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + apiToken
  };

  return { headers };
};

export const useCms = () => {
  const { headers } = useAuthHeaders();

  const getContent = async path => {
    const url = apiConfig.cmsUri + path;
    return await fetch(url, {
      method: "GET",
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}.`);
        }
        return response.json();
      })
      .catch(error => {
        throw error;
      });
  };

  const getMainTopics = async () => {
    return await getContent("/main-topics");
  };

  const getMainTopic = async (mainTopicId: number) => {
    return await getContent(`/main-topics/${mainTopicId}`);
  };

  const getSubTopics = async () => {
    return await getContent(`/sub-topics/`);
  };

  const getSubTopicPerSubTopicId = async (subTopicId: number) => {
    return await getContent(`/sub-topics/` + subTopicId);
  };

  const getSubTopicsPerMainTopicId = async (mainTopicId: number) => {
    return await getContent(`/sub-topics/?main_topic.id=` + mainTopicId);
  };

  const getQuizPerSubTopicId = async (subTopicId: number): Promise<Quiz> => {
    return await getContent(`/sub-topics/` + subTopicId + `/quiz`);
  };

  const getNumberOfContentPerSubTopicId = async (
    subTopicId: number
  ): Promise<ISubTopicContentCount> => {
    return await getContent(`/sub-topics/` + subTopicId + `/content-count`);
  };

  return {
    getMainTopics,
    getSubTopics,
    getSubTopicPerSubTopicId,
    getSubTopicsPerMainTopicId,
    getMainTopic,
    getQuizPerSubTopicId,
    getNumberOfContentPerSubTopicId
  };
};
