import React, { useEffect } from "react";
import { Content, SubTopic } from "../../../utils/types";
import styles from "./TopicContent.module.css";
import { useApi } from "../../../hooks/api";
import { iframe, image, video } from "../../../utils/showdownExtensions";

type TopicContentProps = {
  content: Content;
  activeSubtopic: SubTopic;
  activeContent: Content;
  updateSubtopicMenu: Function;
};

const TopicContent = ({
  content,
  activeSubtopic,
  activeContent,
  updateSubtopicMenu
}: TopicContentProps) => {
  const { postContentProgess } = useApi();

  useEffect(() => {
    postContentProgess(activeSubtopic.id, activeContent.id, true).then(_ => {
      updateSubtopicMenu(activeSubtopic.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubtopic.id, activeContent.id]);

  const Converter = require("react-showdown").Converter;
  require("showdown-youtube");
  const converter = new Converter({
    extensions: [video, image, "youtube", iframe]
  });
  const reactElement = converter.convert(content.Content);

  return (
    <>
      <div className={styles.content}>
        <div className={styles.title}>{content.Title}</div>
        {reactElement}
      </div>
    </>
  );
};

export default TopicContent;
