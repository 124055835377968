import React from "react";
import { useAuth0 } from "../../react-auth0-spa";

import styles from "./LogInComponent.module.css";

const LogInComponent = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <button
        className={styles.button}
        onClick={() =>
          loginWithRedirect({ redirect_uri: `${window.location.origin}` })
        }
      >
        Logg inn
      </button>
    </div>
  );
};

export default LogInComponent;
