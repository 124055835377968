/* eslint-disable no-self-assign*/

import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Loading from "./components/Loading/Loading.tsx";
import Home from "./views/Home.tsx";
import Topic from "./views/Topic/Topic.tsx";
import Profile from "./views/Profile.tsx";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import styles from "./variables.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import Logout from "components/Logout/Logout";
initFontAwesome();

const App = () => {
  const { loading, isAuthenticated } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated && history.location !== "/") {
    history.push("/");
  }

  return (
    <Router history={history}>
      <div id="app" className={styles.app}>
        <Container>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/logout" exact component={Logout} />
            <Route
              path="/dashboard"
              exact
              component={() => (window.location = window.location)}
            />
            <Route path="/topic/:id" exact component={Topic} />
            <PrivateRoute path="/profile" component={Profile} />
          </Switch>
        </Container>
      </div>
    </Router>
  );
};

export default App;
